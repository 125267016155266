import React, {useEffect, useRef, useState} from 'react';
import {Button, Input, InputRef, Space, Table, Tabs} from "antd";
import {ColumnsType, ColumnType, FilterConfirmProps} from 'antd/es/table/interface';
import {CaretDownOutlined, CaretUpOutlined, SearchOutlined, StarFilled, StarOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {formatNumber} from "../helpers/numbers";


interface ReportItem {
    id: string
    price: number
    price24hChange: number
    openInterest: number
    volume24h: number
    volume24hChange: number
    volume4hChange: number
    oi24hChange: number
    oi4hChange: number
    ma501h: number
    ma5015m: number
    updatedAt: string
}

type DataIndex = keyof ReportItem;

const formatPercentage = (value: number) => {
    const text = formatNumber(value / 100, {
        percentage: true,
        compact: true,
        fractionDigits: 2,
    });
    const color = value === 0 ? "black" : value > 0 ? 'green' : 'red';
    let fontWeight = value > 10 ? "bold": "normal";
    let icon = value > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />;
    return <span style={{color: color, fontWeight: fontWeight}}>{icon}{text}</span>;
}

const Report: React.FC = () => {
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef<InputRef>(null);

    // watchlist
    const [watchlist, setWatchlist] = useState<string[]>([]);
    useEffect(() => {
        const storedWatchlist = localStorage.getItem('watchlist');
        if (storedWatchlist) {
            setWatchlist(JSON.parse(storedWatchlist));
        }
    }, []);
    const toggleWatchlist = (id: string) => {
        const newWatchlist = watchlist.includes(id)
            ? watchlist.filter((itemId) => itemId !== id)
            : [...watchlist, id];
        setWatchlist(newWatchlist);
        localStorage.setItem('watchlist', JSON.stringify(newWatchlist));
    };

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<ReportItem> => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters, close}) => (
            <div style={{padding: 8}} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{marginBottom: 8, display: 'block'}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined/>}
                        size="small"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({closeDropdown: false});
                            setSearchText((selectedKeys as string[])[0]);
                            setSearchedColumn(dataIndex);
                        }}
                    >
                        Filter
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            close();
                        }}
                    >
                        close
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined style={{color: filtered ? '#1677ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const columns: ColumnsType<ReportItem> = [
        {
            title: '',
            key: 'watchlist',
            render: (_, record) => {
                const isWatchlist = watchlist.includes(record.id);
                return isWatchlist ? (
                    <StarFilled style={{color: "rgb(246, 184, 126)"}} onClick={() => toggleWatchlist(record.id)} />
                ) : (
                    <StarOutlined onClick={() => toggleWatchlist(record.id)} />
                );
            },
        },
        {
            title: 'ID', dataIndex: 'id', key: 'id',
            ...getColumnSearchProps('id'),
            render: (text, _) => {
                return <span style={{fontWeight: 'bold'}}>{text}</span>;
            },
        },
        {
            title: 'Price', dataIndex: 'price', key: 'price',
            sorter: (a, b) => a.price - b.price,
            render: (text, record) => {
                text = formatNumber(record.price);
                let price24hChange = formatPercentage(record.price24hChange);
                return <span>{text}<br/>{price24hChange}</span>;
            },
        },
        {
            title: 'Volume 24h', dataIndex: 'volume24h', key: 'volume24h',
            sorter: (a, b) => a.volume24h - b.volume24h,
            render: (text, record) => {
                text = formatNumber(record.volume24h, {
                    compact: true,
                });
                return <span>{text}</span>;
            },
        },
        {
            title: 'Vol 24h Change', dataIndex: 'volume24hChange', key: 'volume24hChange',
            sorter: (a, b) => a.volume24hChange - b.volume24hChange,
            render: (_, record) => {
                return formatPercentage(record.volume24hChange);
            },
        },
        {
            title: 'Vol 4h Change', dataIndex: 'volume4hChange', key: 'volume4hChange',
            sorter: (a, b) => a.volume4hChange - b.volume4hChange,
            render: (_, record) => {
                return formatPercentage(record.volume4hChange);
            },
        },
        {
            title: 'Open Interest', dataIndex: 'openInterest', key: 'openInterest',
            sorter: (a, b) => a.openInterest - b.openInterest,
            render: (text, record) => {
                text = formatNumber(record.openInterest, {
                    compact: true,
                });
                return <span>{text}</span>;
            },
        },
        {
            title: 'OI 24h Change', dataIndex: 'oi24hChange', key: 'oi24hChange',
            sorter: (a, b) => a.oi24hChange - b.oi24hChange,
            render: (_, record) => {
                return formatPercentage(record.oi24hChange);
            },
        },
        {
            title: 'OI 4h Change', dataIndex: 'oi4hChange', key: 'oi4hChange',
            sorter: (a, b) => a.oi4hChange - b.oi4hChange,
            render: (_, record) => {
                return formatPercentage(record.oi4hChange);
            },
        },
        {
            title: 'MA50 1h', dataIndex: 'ma501h', key: 'ma501h',
            sorter: (a, b) => (a.price - a.ma501h) - (b.price - b.ma501h),
            render: (text, record) => {
                let ma501h = formatNumber(record.ma501h);
                let value = record.price - record.ma501h;
                let percent = formatPercentage(value / record.price * 100);
                return <span>{ma501h}<br />{percent}</span>;
            },
        },
        {
            title: 'MA50 15m', dataIndex: 'ma5015m', key: 'ma5015m',
            sorter: (a, b) => (a.price - a.ma5015m) - (b.price - b.ma5015m),
            render: (text, record) => {
                let ma5015m = formatNumber(record.ma5015m);
                let value = record.price - record.ma5015m;
                let percent = formatPercentage(value / record.price * 100);
                return <span>{ma5015m}<br />{percent}</span>;
            },
        },
        {
            title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt',
            render: (text, record) => {
                const dateFormatter = new Intl.DateTimeFormat("en-GB", { dateStyle: "short", timeStyle: "medium" });
                text = dateFormatter.format(new Date(record.updatedAt));
                return <span>{text}</span>;
            },
        },
    ];

    const [data, setData] = useState<ReportItem[]>([]);

    useEffect(() => {
        fetch('https://api.tradeagg.net/api/report')
            .then(response => response.json())
            .then(data => setData(data))
            .catch(error => console.error('Error:', error));
    }, []);

    return <Tabs
        defaultActiveKey="all"
        type="card"
        size="middle"
        items={[
            {
                key: "all",
                label: "All",
                children: <Table dataSource={data} columns={columns}/>,
            },
            {
                key: "watchlist",
                label: "Watchlist",
                children: <Table dataSource={data.filter(id => watchlist.includes(id.id))} columns={columns}/>,
            }
        ]}
    />
};

export default Report;
