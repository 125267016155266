type FormatOption = {
    locale: string;
    compact: boolean;
    fractionDigits: number;
    keepTrailingZeros: boolean;
    significantDigits?: number;
    percentage?: boolean;
    currency?: 'USD' | string; // https://en.wikipedia.org/wiki/ISO_4217#List_of_ISO_4217_currency_codes
    thousandGrouping: boolean;
    signDisplay?: 'auto' | 'never' | 'always' | 'exceptZero';
};

const defaultFormat: FormatOption = {
    locale: 'en-US',
    compact: false,
    keepTrailingZeros: false,
    fractionDigits: 3,
    percentage: false,
    currency: '',
    thousandGrouping: true,
};

const defaultFormatter = new Intl.NumberFormat('en-US', {
    // notation: 'compact',
    maximumFractionDigits: 3,
    useGrouping: true,
});

/**
 * convert format option to Intl options
 */
const parseConfig = (fmt: FormatOption): Intl.NumberFormatOptions => {
    const ret: Intl.NumberFormatOptions = {
        maximumFractionDigits: fmt.fractionDigits,
    };

    if (fmt.compact) {
        ret.notation = 'compact';
    }
    if (fmt.keepTrailingZeros) {
        ret.minimumFractionDigits = fmt.fractionDigits;
    }
    if (fmt.significantDigits) {
        ret.minimumSignificantDigits = fmt.significantDigits;
        // ret.maximumFractionDigits = null;
    }
    if (fmt.percentage) {
        ret.style = 'percent';
    }
    if (fmt.currency) {
        ret.style = 'currency';
        ret.currency = fmt.currency;
        ret.minimumFractionDigits = fmt.keepTrailingZeros ? fmt.fractionDigits : 0;
    }
    if (fmt.thousandGrouping) {
        ret.useGrouping = fmt.thousandGrouping;
    }
    ret.signDisplay = fmt.signDisplay;

    return ret;
};

export const formatNumber = (input: number, options?: Partial<FormatOption>): string => {
    if (input == null || isNaN(input)) {
        return '';
    }
    let formatter: Intl.NumberFormat;
    if (!options) {
        formatter = defaultFormatter;
    } else {
        formatter = new Intl.NumberFormat(
            'en-US',
            parseConfig({
                ...defaultFormat,
                ...options,
            }),
        );
    }
    return formatter.format(input);
};
