import React from 'react';
import './App.css';
import 'antd/dist/reset.css';
import Report from './components/report';

function App() {
  return (
    <div className="App">
      <h1>Dashboard</h1>
      <Report></Report>
    </div>
  );
}

export default App;
